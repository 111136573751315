






























import {Component, Mixins} from 'vue-property-decorator';
import VideoPreviewMixin from '@/mixins/VideoPreviewMixin';
import SearchMixin from '@/mixins/SearchMixin';
import ModelCardNew from '@/components/ModelCard.vue';

@Component({
  components: {ModelCardNew},
})
export default class SearchNew extends Mixins(SearchMixin, VideoPreviewMixin) {}
